/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {


  // utility function to detect mobile
  function isMobileWidth() {
    return $('.menuToggle').is(':visible');
  }

  // check if element is in the viewport
  function isInViewport(el) {
    var elementTop = el.offset().top;
    var elementBottom = elementTop + el.outerHeight();
    var viewportTop = jQuery(window).scrollTop();
    var viewportBottom = viewportTop + jQuery(window).height();
    return elementBottom > viewportTop && elementTop < viewportBottom;
  }


  function shareMobile(el) {
    $(window).on('load resize', function() {
      var throttled = false;
      var delay = 250;

      // only run if we're not throttled
      if (!throttled) {
        var w = window.innerWidth;
        var body = $('body');

        if (w < 992) {
          if (!body.hasClass('mobile-share')) {
            $('aside').prepend($(el));
            body.addClass('mobile-share');
          }
        }
        else {
          if (body.hasClass('mobile-share')) {
            $('aside').append($(el));
            body.removeClass('mobile-share');
          }
        }

        // we're throttled!
        throttled = true;

        // set a timeout to un-throttle
        setTimeout(function() {
          throttled = false;
        }, delay);
      }
    });
  }


  function issue_accordion() {
    // collapsible item toggle
    $('.issue-intro__accordion-item .issue-intro__accordion-title').on('click', function () {
      var this_item = $(this);

      // check if current accordion is open
      var is_open = false;
      if (this_item.hasClass('active')) {
        is_open = true;
      }

      // close all accordions
      $('.issue-intro__accordion-item .issue-intro__accordion-title').each( function() {
        var this_accordion = $(this);
        this_accordion.removeClass('active');
        this_accordion.parents('.issue-intro__accordion-item').removeClass('active');
        this_accordion.next().slideUp();
      });

      if (is_open === false) {
        this_item.addClass('active');
        this_item.parents('.issue-intro__accordion-title').addClass('active');
        this_item.next('.issue-intro__accordion-description').slideDown();
      }

    });
  }

  var issue_stories_settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    mobileFirst: true,
    nextArrow: '',
    prevArrow: '',
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [{
      breakpoint: 1199,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: '<button type="button" class="slick-next"><img src="/wp-content/themes/explore-beyond/dist/images/arrow_right.svg" /></button>',
        prevArrow: '<button type="button" class="slick-prev"><img src="/wp-content/themes/explore-beyond/dist/images/arrow_left.svg" /></button>',
        dots: false
      }
    }, {
      breakpoint: 991,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      }
    },{
      breakpoint: 450,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        nextArrow: '',
        prevArrow: '',
        dots: true
      }
    }]
  };

  var issue_stories_resp_settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    mobileFirst: true,
    nextArrow: '',
    prevArrow: '',
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [{
      breakpoint: 1199,
      settings: "unslick"
    }, {
      breakpoint: 991,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      }
    }, {
      breakpoint: 449,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        nextArrow: '',
        prevArrow: '',
        dots: true
      }
    }]
  };

  var issue_videos_settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    mobileFirst: true,
    nextArrow: '',
    prevArrow: '',
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [{
      breakpoint: 1299,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: '<button type="button" class="slick-next"><img src="/wp-content/themes/explore-beyond/dist/images/arrow_right.svg" /></button>',
        prevArrow: '<button type="button" class="slick-prev"><img src="/wp-content/themes/explore-beyond/dist/images/arrow_left.svg" /></button>',
        dots: false
      }
    }, {
      breakpoint: 1199,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      }
    }, {
      breakpoint: 991,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    }, {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    }]
  };

  var issue_videos_resp_settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    mobileFirst: true,
    nextArrow: '',
    prevArrow: '',
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [{
      breakpoint: 991,
      settings: "unslick"
    }, {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    }]
  };

  var other_issues_settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    mobileFirst: true,
    nextArrow: '',
    prevArrow: '',
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [{
      breakpoint: 1299,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: '<button type="button" class="slick-next"><img src="/wp-content/themes/explore-beyond/dist/images/arrow_right.svg" /></button>',
        prevArrow: '<button type="button" class="slick-prev"><img src="/wp-content/themes/explore-beyond/dist/images/arrow_left.svg" /></button>',
        dots: false
      }
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        nextArrow: '',
        prevArrow: '',
        dots: true
      }
    }]
  };

  var other_issues_resp_settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    mobileFirst: true,
    nextArrow: '',
    prevArrow: '',
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [{
      breakpoint: 1300,
      settings: "unslick"
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    }]
  };

  var issue_campaigns_settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    mobileFirst: true,
    nextArrow: '',
    prevArrow: '',
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [{
      breakpoint: 1300,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: '<button type="button" class="slick-next"><img src="/wp-content/themes/explore-beyond/dist/images/arrow_right.svg" /></button>',
        prevArrow: '<button type="button" class="slick-prev"><img src="/wp-content/themes/explore-beyond/dist/images/arrow_left.svg" /></button>',
        dots: false
      }
    }, {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        nextArrow: '',
        prevArrow: '',
        dots: true
      }
    }]
  };

  var issue_campaigns_resp_settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    mobileFirst: true,
    nextArrow: '',
    prevArrow: '',
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [{
      breakpoint: 992,
      settings: "unslick"
    }]
  };

  var issue_campaigns_settings2 = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    mobileFirst: true,
    nextArrow: '',
    prevArrow: '',
    autoplay: true,
    autoplaySpeed: 5000,
  };

  var issue_campaigns_resp_settings2 = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    mobileFirst: true,
    nextArrow: '',
    prevArrow: '',
    autoplay: true,
    autoplaySpeed: 5000
  };

  var issue_quotes_settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    mobileFirst: true,
    nextArrow: '',
    prevArrow: '',
    autoplay: true,
    autoplaySpeed: 10000
  }

  var issue_stats_resp_settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    mobileFirst: true,
    nextArrow: '',
    prevArrow: '',
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [{
      breakpoint: 992,
      settings: "unslick"
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    }]
  };

  var issue_callouts_resp_settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    mobileFirst: true,
    nextArrow: '',
    prevArrow: '',
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [{
      breakpoint: 767,
      settings: "unslick"
    }]
  };


  function carousel_init(selector, selector_wrap, main_settings, resp_settings, desktop_carousel, mobile_carousel, carousel_items, breakpoint) {

    var w = window.innerWidth;
    var issue_stories = $(selector);

    if (desktop_carousel && (issue_stories.length > carousel_items)) {
      $(selector_wrap).slick(main_settings);
    }
    else {

      if (mobile_carousel) {

        var sl = $(selector_wrap).slick(resp_settings);

        $(window).on('resize', function() {
          var throttled = false;
          var delay = 250;

          // only run if we're not throttled
          if (!throttled) {

            if( window.innerWidth < breakpoint &&  !sl.hasClass('slick-initialized')) {
              $(selector_wrap).slick(resp_settings);
            }

            // we're throttled!
            throttled = true;
            // set a timeout to un-throttle
            setTimeout(function() {
              throttled = false;
            }, delay);
          }
        });

      }

    }
  }


  // get page that vimeo video plays on
  // takes body jquery element as input,
  function getVideoPage(body_el) {
    var vimeo_view_page = '';
    // determine page and set label data
    if (body_el.hasClass('home')) {
      vimeo_view_page = 'Homepage';
    }
    else if (body_el.hasClass('stories')) {
      vimeo_view_page = 'Stories';
    }
    else if (body_el.hasClass('about-us')) {
      vimeo_view_page = 'About Us';
    }
    else if (body_el.hasClass('broadband-infrastructure')) {
      vimeo_view_page = 'Broadband Infrastructure';
    }
    else if (body_el.hasClass('distance-learning')) {
      vimeo_view_page = 'Distance Learning';
    }
    else if (body_el.hasClass('rural-broadband')) {
      vimeo_view_page = 'Rural Broadband';
    }
    else if (body_el.hasClass('americas-wi-fi')) {
      vimeo_view_page = "America's Wi-fi";
    }
    else if (body_el.hasClass('privacy')) {
      vimeo_view_page = 'Privacy';
    }
    else if (body_el.hasClass('diversity-on-tv')) {
      vimeo_view_page = 'Diversity on TV';
    }
    else if (body_el.hasClass('single-campaign')) {
      vimeo_view_page = 'Campaign Page';
    }

    return vimeo_view_page;
  }

  // collect the final data and send to GTM
  function collectAndSendVimeoData(vimeo_video_player, body_el, vimeo_url, vimeo_progress) {
    vimeo_video_player.getVideoTitle().then(function(title) {
        var vimeo_title = title;
        var vimeo_view_page = getVideoPage(body_el); // get vimeo view page
        var vimeo_label = vimeo_title + " - View from " + vimeo_view_page; // set vimeo label

        // send to vimeo
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({'event': 'vimeo_video',
                        'vimeo_video_url': vimeo_url,
                        'vimeo_video_name': vimeo_title,
                        'vimeo_video_progress': vimeo_progress,
                        'vimeo_video_label': vimeo_label
                       });
    }).catch(function(error) {
        console.log("Error: no video title available");
    });
  }

  // function for tracking video progress in gtm
  function trackVimeoVideoData(vimeo_video) {

    // capture data from videos played on the home page
    // custom vimeo video play event
    var vimeo_url = vimeo_video.attr('src');
    var vimeo_video_player = new Vimeo.Player(vimeo_video);
    var vimeo_progress = '';
    var body_el = $('body');
    var fire_10_percent = false;
    var fire_25_percent = false;
    var fire_50_percent = false;
    var fire_75_percent = false;
    var fire_95_percent = false;

    vimeo_video_player.on('timeupdate', function(data) {
      var fire_event = false;

      // capture video progress
      if ((data.percent > 0.1) && (fire_10_percent == false)) {
        vimeo_progress = "Viewed 10%";
        fire_10_percent = true;
        fire_event = true;
      }
      else if ((data.percent > 0.25) && (fire_25_percent == false)) {
        vimeo_progress = "Viewed 25%";
        fire_25_percent = true;
        fire_event = true;
      }
      else if ((data.percent > 0.5) && (fire_50_percent == false))  {
        vimeo_progress = "Viewed 50%";
        fire_50_percent = true;
        fire_event = true;
      }
      else if ((data.percent > 0.75) && (fire_75_percent == false)) {
        vimeo_progress = "Viewed 75%";
        fire_75_percent = true;
        fire_event = true;
      }
      else if ((data.percent > 0.95) && (fire_95_percent == false)) {
        vimeo_progress = "Viewed 95%";
        fire_95_percent = true;
        fire_event = true;
      }

      // only capture the remaining data if we need to
      // fire an event and send data to gtm
      if (fire_event == true) {
        // colllect final data points and send to GTM
        collectAndSendVimeoData(vimeo_video_player, body_el, vimeo_url, vimeo_progress);
      }

    });

    // fire when video is complete
    vimeo_video_player.on('ended', function(data) {
      vimeo_progress = "Viewed 100%";
      // colllect final data points and send to GTM
      collectAndSendVimeoData(vimeo_video_player, body_el, vimeo_url, vimeo_progress);
    });
  } // end gtm tracking function


  function issueSignUpSubmit() {
    if ($('body').hasClass('primary-issue-selected')) {
      $('.form-bottom__form #gform_submit_button_1, .sidebar-block #gform_submit_button_14').on('click', function() {
        // send event to GTM
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({'event': 'issue_sign_up',
                        'issue_sign_up_url': window.location.href
                       });
      });
    }
  }


  function resizeGOTV() {
   var gotv_widget = $('.civicactioncenter-iframe').attr('scrolling', 'yes');
  }

  var docEl = $('html, body');
  var wrap = $('.wrap.container');
  var scrollTop;

  // to lock body behind overlay menu on mobile devices
  function lockBody() {
    if(window.pageYOffset) {
      scrollTop = window.pageYOffset;

      wrap.css({
        top: - (scrollTop)
      });
    }

    docEl.css({
      height: "100%",
      overflow: "hidden"
    });
  }

  // to unlock body behind overlay menu on mobile devices
  function unlockBody() {
    docEl.css({
      height: "",
      overflow: ""
    });

    wrap.css({
      top: ''
    });

    window.scrollTo(0, scrollTop);
    window.setTimeout(function () {
      scrollTop = null;
    }, 0);
  }

  function showModal() {
    $('.modal-wrap').toggleClass('closed');
    $('.modal-overlay').toggleClass('closed');

    var body = $('body');
    body.toggleClass('modal-show');

    if (window.innerWidth < 768) {
      if (body.hasClass('modal-show')) {
        lockBody();
      }
      else {
        unlockBody();
      }
    }
  }

  function closeModal() {
    $('.modal-wrap').addClass('closed');
    $('.modal-overlay').addClass('closed');

    var body = $('body');
    body.removeClass('modal-show');

    if (window.innerWidth < 768) {
      unlockBody();
    }
  }

  function triggerExitModal() {
    var is_modal_show = window.sessionStorage.getItem('modalShow');

    if(is_modal_show != 'modalShown') {
      window.sessionStorage.setItem('modalShow','modalShown');
      showModal();
    }
  }

  function exitModal() {
    if ($('body').hasClass('modal-active')) {
      var mouseY = 0;
      var topValue = 0;

      $(window).on("mouseout",function(e) {
          mouseY = e.clientY;

          if(mouseY < topValue) {
            triggerExitModal();
          }
      });

      $(window).on("blur", function(e) {
        triggerExitModal();
      });
    }
  }


  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // Make nav smaller on scroll
        $(document).scroll(function () {
          var $nav = $(".banner.navbar");
          $nav.toggleClass('scrolled', $(this).scrollTop() > $nav.height());
        });

        // handle modal show and hide
        $('.issue-header__text-wrap button, .page-template-template-home-page .issue-cta button, .modal-wrap .close').on('click', function(e) {
          showModal();
          e.stopPropagation();
        });

        $('body').click(function (event) {
          if (!$('.modal-wrap').hasClass('closed')) {
            if(!$(event.target).closest('.modal-wrap').length && !$(event.target).is('.modal-wrap')) {
              closeModal();
            }
          }
        });


        $('.menuToggle').on('click', function () {
          var menu = $('[data-action="menu-main"]');
          var body = $('body');

          menu.toggleClass('open');

          if (menu.hasClass('open')) {
            lockBody();
          }
          else {
            unlockBody();
          }

        });


        // Add smooth scrolling to all links
        $("a").on('click', function(event) {

          // Make sure this.hash has a value before overriding default behavior
          if (this.hash !== "") {
            // Prevent default anchor click behavior
            event.preventDefault();

            // Store hash
            var hash = this.hash;

            // Using jQuery's animate() method to add smooth page scroll
            // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
            $('html, body').animate({
              scrollTop: $(hash).offset().top
            }, 800, function(){

              // Add hash (#) to URL when done scrolling (default click behavior)
              window.location.hash = hash;
            });
          } // End if
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        //SVG
        //unfurl into inline from image
        $('img.svg').each(function () {
          var $img = $(this);
          var imgID = $img.attr('id');
          var imgClass = $img.attr('class');
          var imgURL = $img.attr('src');

          $.get(imgURL, function (data) {
            // Get the SVG tag, ignore the rest
            var $svg = $(data).find('svg');

            // Add replaced image's ID to the new SVG
            if (typeof imgID !== 'undefined') {
              $svg = $svg.attr('id', imgID);
            }
            // Add replaced image's classes to the new SVG
            if (typeof imgClass !== 'undefined') {
              $svg = $svg.attr('class', imgClass + ' replaced-svg');
            }

            // Remove any invalid XML tags as per http://validator.w3.org
            $svg = $svg.removeAttr('xmlns:a');

            // Check if the viewport is set, else we gonna set it if we can.
            if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
              $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'));
            }

            // Replace image with new SVG
            $img.replaceWith($svg);

          }, 'xml');

        });

        // track video data in campaign item overlays
        var about_video = $('.about-us .main iframe');
        if (about_video.length) {
          trackVimeoVideoData(about_video);
        }

      }
    },
    // Home page
    'page_template_default': {
      init: function() {
        resizeGOTV();
      },
      finalize: function() {
        carousel_init('.issue-stories__main-wrap .issue-story', '.issue-stories__main-wrap', issue_stories_settings, issue_stories_resp_settings, true, true, 4, 1200);
      }
    },
    'page_template_template_home_page': {
      init: function() {

      },
      finalize: function() {

        // issue stories initialization
        carousel_init('.issue-stories__main-wrap .issue-story', '.issue-stories__main-wrap', issue_stories_settings, issue_stories_resp_settings, true, true, 4, 1200);
        carousel_init('.other-issues__main-wrap .other-issue', '.other-issues__main-wrap', other_issues_settings, other_issues_resp_settings, true, true, 4, 768);

        // track vimeo data for cta video
        var vimeo_video_iframe = $('.issue-cta__video iframe');
        if (vimeo_video_iframe.length) {
          $(document).on('lazybeforeunveil', function(event) {
            var lazy_loaded_asset = $(event.target);
            if (lazy_loaded_asset.is('iframe') && lazy_loaded_asset.parent().hasClass('issue-cta__video')) {
              var data_src = lazy_loaded_asset.attr('data-src');
              lazy_loaded_asset.removeAttr('data-src');
              lazy_loaded_asset.attr('src', data_src);
              trackVimeoVideoData(lazy_loaded_asset);
            }
          });
        }

      }
    },
    'issue_template_default': {
      init: function() {
        // accordion on new campaign landing pages
        issue_accordion();
      },
      finalize: function() {
        // send issue footer sign-up events to gtm
        issueSignUpSubmit();

        // carousel initializations
        carousel_init('.issue-stories__main-wrap .issue-story', '.issue-stories__main-wrap', issue_stories_settings, issue_stories_resp_settings, true, true, 4, 1200);
        carousel_init('.issue-videos__main-wrap .issue-video', '.issue-videos__main-wrap', issue_videos_settings, issue_videos_resp_settings, true, true, 3, 992);
        carousel_init('.other-issues__main-wrap .other-issue', '.other-issues__main-wrap', other_issues_settings, other_issues_resp_settings, true, true, 4, 1300);
        carousel_init('.issue-campaigns__wrap .issue-campaign', '.issue-campaigns__wrap', issue_campaigns_settings, issue_campaigns_resp_settings, true, true, 3, 992);
        carousel_init('.issue-quotes__main-wrap .issue-quote', '.issue-quotes__main-wrap', issue_quotes_settings, {}, true, false, 1, 0);
        carousel_init('.issue-stats__inner-wrap .issue-stat', '.issue-stats__inner-wrap', {}, issue_stats_resp_settings, false, true, 0, 992);
        carousel_init('.issue-callouts__main-wrap .issue-callout', '.issue-callouts__main-wrap', {}, issue_callouts_resp_settings, false, true, 0, 992);

        // track vimeo data for cta video
        var vimeo_video_iframe = $('.issue-cta__video iframe');
        if (vimeo_video_iframe.length) {
          $(document).on('lazybeforeunveil', function(event) {
            var lazy_loaded_asset = $(event.target);
            if (lazy_loaded_asset.is('iframe') && lazy_loaded_asset.parent().hasClass('issue-cta__video')) {
              var data_src = lazy_loaded_asset.attr('data-src');
              lazy_loaded_asset.removeAttr('data-src');
              lazy_loaded_asset.attr('src', data_src);
              trackVimeoVideoData(lazy_loaded_asset);
            }
          });
        }

      }
    },
    'campaign_template_default': {
      init: function() {
      },
      finalize: function() {
        issueSignUpSubmit();

        carousel_init('.campaign-issue-callouts__main-wrap .campaign-issue-callout', '.campaign-issue-callouts__main-wrap', issue_campaigns_settings2, issue_campaigns_resp_settings2, true, true, 1, 992);

        // track vimeo data for cta video
        var vimeo_video_iframe = $('.single-campaign .campaign-video-top iframe');
        if (vimeo_video_iframe.length) {
          $(document).on('lazybeforeunveil', function(event) {
            var lazy_loaded_asset = $(event.target);
            if (lazy_loaded_asset.is('iframe') && lazy_loaded_asset.parent().hasClass('campaign-video-top')) {
              var data_src = lazy_loaded_asset.attr('data-src');
              lazy_loaded_asset.removeAttr('data-src');
              lazy_loaded_asset.attr('src', data_src);
              trackVimeoVideoData(lazy_loaded_asset);
            }
          });
        }

        // JavaScript to be fired after the init JS
        $(window).scroll(function() {
            if ($(this).scrollTop() >= 50) {
                $('.return-to-top').fadeIn(200);
            } else {
                $('.return-to-top').fadeOut(200);
            }
        });
        $('.return-to-top').click(function() {
            $('body,html').animate({
                scrollTop : 0
            }, 500);
        });

      }
    },
    'thank_you_page_template_default': {
      init: function() {

      },
      finalize: function() {

        function numberWithCommas(x) {
          return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }

        function animateNum(selector, range, duration) {
          var current = 0;
          var stepTime = Math.floor(duration / range);
          if (stepTime < 10) {
            stepTime = 10;
          }
          var numSteps = Math.floor(duration / stepTime);
          var increment = Math.floor(range / numSteps);
          var obj = document.querySelector(selector);
          var timer = setInterval(function() {
              current += increment;
              if (current > range) {
                current = range;
              }
              obj.innerHTML = numberWithCommas(current);
              if (current === range) {
                  clearInterval(timer);
              }
          }, stepTime);
        }

        var numWrap = document.querySelector('.thank-you-header__num');
        if (numWrap) {
          var numLimit = parseInt(numWrap.innerHTML);
          animateNum('.thank-you-header__num', numLimit, 2000);
        }

        // track addthis links on thank you pages
        var addthis_link = $('.addthis_toolbox a');
        addthis_link.on('click', function() {
          var social_share_type = $(this).attr('title');
          var campaign_share_name = $(this).closest('.addthis_toolbox').attr('addthis:title');

          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({'event': 'thank_you_page_social_share',
                          'social_share_type': social_share_type,
                          'campaign_share_name': campaign_share_name });
        });


        // initialize carousels
        carousel_init('.issue-stories__main-wrap .issue-story', '.issue-stories__main-wrap', issue_stories_settings, issue_stories_resp_settings, true, true, 4, 1200);
        carousel_init('.other-issues__main-wrap .other-issue', '.other-issues__main-wrap', other_issues_settings, other_issues_resp_settings, true, true, 4, 1300);
      }
    },
    'page_template_template_get_involved': {
      init: function() {
        $('#get-involved').imagesLoaded( function() {
          // images have loaded
          $('.grid').colcade({
            columns: '.grid-col',
            items: '.campaign-item'
          });

        });
      },
      finalize: function() {
      }
    },
    'page_template_template_stories': {
      init: function() {
        $('.filter-form__label').click(function() {
          $(this).parents('form').submit();
        });
      },
      finalize: function() {
        carousel_init('.other-issues__main-wrap .other-issue', '.other-issues__main-wrap', other_issues_settings, other_issues_resp_settings, true, true, 4, 1300);
      }
    },
    'stories_template_default': {
      init: function() {

      },
      finalize: function() {
        exitModal();
        issueSignUpSubmit();
        shareMobile('.sidebar-block--sticky');
        carousel_init('.other-issues__main-wrap .other-issue', '.other-issues__main-wrap', other_issues_settings, other_issues_resp_settings, true, true, 4, 1300);
      }
    },
    'videos_template_default': {
      init: function() {
      },
      finalize: function() {
        shareMobile('.sidebar-block--share');

        // track vimeo data for cta video
        var vimeo_video_iframe = $('.single-videos .video-wrap iframe');
        if (vimeo_video_iframe.length) {
          trackVimeoVideoData(vimeo_video_iframe);
        }

        carousel_init('.other-issues__main-wrap .other-issue', '.other-issues__main-wrap', other_issues_settings, other_issues_resp_settings, true, true, 4, 1300);
      }
    },
    'page_template_toolkit': {
      init: function() {
        // JavaScript to be fired on the home page

        $('.copytoclipboard .innercontent').on( 'click touch', function (e) {
          if (navigator.userAgent.match(/ipad|ipod|iphone/i) ) {
            //do nothing
          } else {
            // var $temp = $("<input>");
            // $("body").append($temp);
            // $temp.val($(this).text().trim()).select();
            var str = $(this).html();
            function listener(e) {
              e.clipboardData.setData("text/html", str);
              e.clipboardData.setData("text/plain", str);
              e.preventDefault();
            }
            document.addEventListener("copy", listener);
            document.execCommand("copy");
            document.removeEventListener("copy", listener);
            // $temp.remove();
            $(this).closest('.banner-name').find('.cpcimg').fadeIn('1000');
            setTimeout(function () {
              $('.cpcimg').fadeOut('1000');
            }, 2000);
            var sel, range;
            var el = $(this)[0];
            if (window.getSelection && document.createRange) { //Browser compatibility
              sel = window.getSelection();
              if (sel.toString() == '') { //no text selection
                window.setTimeout(function () {
                  range = document.createRange(); //range object
                  range.selectNodeContents(el); //sets Range
                  sel.removeAllRanges(); //remove all ranges from selection
                  sel.addRange(range);//add Range to a Selection.
                }, 1);
              }
            } else if (document.selection) { //older ie
              sel = document.selection.createRange();
              if (sel.text == '') { //no text selection
                range = document.body.createTextRange();//Creates TextRange object
                range.moveToElementText(el);//sets Range
                range.select(); //make selection.
              }
            }
          }
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'digital_toolkit': {
      init: function() {
        // The function actually applying the offset
        function offsetAnchor() {
          if (location.hash.length !== 0) {
            window.scrollTo(window.scrollX, window.scrollY - 120);
          }
        }

        // Captures click events of all <a> elements with href starting with #
        $(document).on('click', 'a[href^="#"]', function(event) {
          // Click events are captured before hashchanges. Timeout
          // causes offsetAnchor to be called after the page jump.
          window.setTimeout(function() {
            offsetAnchor();
          }, 0);
        });

        // Set the offset when entering page with hash present in the url
        window.setTimeout(offsetAnchor, 0);
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
